import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box, Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, 
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, 
  Typography, IconButton, Select, MenuItem, FormControl, InputLabel
} from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import { addCategory } from '../features/categorySlice';

const initialCategoryState = {
  name: '',
  subcategories: []
};

function Category() {
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.categories.categories);
  const subcategories = useSelector((state) => state.subcategories.subcategories);
  const [open, setOpen] = useState(false);
  const [newCategory, setNewCategory] = useState(initialCategoryState);
  const [selectedSubcategories, setSelectedSubcategories] = useState([]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setNewCategory(initialCategoryState);
    setSelectedSubcategories([]);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewCategory((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubcategoryChange = (e) => {
    setSelectedSubcategories(e.target.value);
  };

  const handleSubmit = () => {
    dispatch(addCategory({ ...newCategory, subcategories: selectedSubcategories }));
    handleClose();
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Typography variant="h4">Categories</Typography>
        <Button variant="contained" color="primary" onClick={handleOpen} startIcon={<AddIcon />}>
          Create Category
        </Button>
      </Box>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Create Category</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Category Name"
            name="name"
            value={newCategory.name}
            onChange={handleChange}
            fullWidth
          />
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel>Assign Subcategory</InputLabel>
            <Select
              multiple
              value={selectedSubcategories}
              onChange={handleSubcategoryChange}
              renderValue={(selected) => selected.map((id) => subcategories.find((sub) => sub.id === id)?.name).join(', ')}
            >
              {subcategories.map((subcategory) => (
                <MenuItem key={subcategory.id} value={subcategory.id}>
                  {subcategory.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit} color="primary">Submit</Button>
        </DialogActions>
      </Dialog>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Subcategories</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {categories.map((category, index) => (
              <TableRow key={index}>
                <TableCell>{category.name}</TableCell>
                <TableCell>{category.subcategories.map((subId) => subcategories.find((sub) => sub.id === subId)?.name).join(', ')}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default Category;
