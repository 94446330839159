import React, { useState } from 'react';
import { TextField, Button, Container } from '@mui/material';

function CustomerForm() {
  const [customerName, setCustomerName] = useState('');
  const [customerEmail, setCustomerEmail] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission
    console.log({ customerName, customerEmail });
  };

  return (
    <Container>
      <h2>Add Customer</h2>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Customer Name"
          value={customerName}
          onChange={(e) => setCustomerName(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Customer Email"
          value={customerEmail}
          onChange={(e) => setCustomerEmail(e.target.value)}
          fullWidth
          margin="normal"
        />
        <Button type="submit" variant="contained" color="primary">
          Add Customer
        </Button>
      </form>
    </Container>
  );
}

export default CustomerForm;
