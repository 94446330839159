import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box, Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, 
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, 
  Typography, IconButton
} from '@mui/material';
import { Add as AddIcon, Search as SearchIcon } from '@mui/icons-material';
import { addUom, updateUom } from '../features/uomSlice';

const initialUomState = {
  unitName: '',
  precision: ''
};

function Uom() {
  const dispatch = useDispatch();
  const uoms = useSelector((state) => state.uoms.uoms);
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [newUom, setNewUom] = useState(initialUomState);
  const [currentUom, setCurrentUom] = useState(null);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setNewUom(initialUomState);
  };

  const handleEditOpen = (uom) => {
    setCurrentUom(uom);
    setNewUom(uom);
    setEditOpen(true);
  };
  const handleEditClose = () => {
    setEditOpen(false);
    setNewUom(initialUomState);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewUom((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = () => {
    dispatch(addUom(newUom));
    handleClose();
  };

  const handleUpdate = () => {
    dispatch(updateUom(newUom));
    handleEditClose();
  };

  const filteredUoms = uoms.filter((uom) =>
    uom.unitName.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <TextField
          variant="outlined"
          label="Search UOM"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          InputProps={{
            startAdornment: <SearchIcon />,
          }}
        />
        <Button variant="contained" color="primary" onClick={handleOpen} startIcon={<AddIcon />}>
          Add UOM
        </Button>
      </Box>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add UOM</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Unit Name"
            name="unitName"
            value={newUom.unitName}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Precision (Value)"
            name="precision"
            value={newUom.precision}
            onChange={handleChange}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit} color="primary">Submit</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={editOpen} onClose={handleEditClose}>
        <DialogTitle>Edit UOM</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Unit Name"
            name="unitName"
            value={newUom.unitName}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Precision (Value)"
            name="precision"
            value={newUom.precision}
            onChange={handleChange}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditClose}>Cancel</Button>
          <Button onClick={handleUpdate} color="primary">Update</Button>
        </DialogActions>
      </Dialog>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Unit Name</TableCell>
              <TableCell>Precision (Value)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredUoms.map((uom, index) => (
              <TableRow key={index} onClick={() => handleEditOpen(uom)} style={{ cursor: 'pointer' }}>
                <TableCell>{uom.unitName}</TableCell>
                <TableCell>{uom.precision}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default Uom;
