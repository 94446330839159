import React from 'react';
import { Button } from '@mui/material';

function UploadButton({ onUpload }) {
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        onUpload(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Button
      variant="contained"
      component="label"
    >
      Upload Image
      <input
        type="file"
        hidden
        onChange={handleFileChange}
      />
    </Button>
  );
}

export default UploadButton;
