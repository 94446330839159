import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  promotionalOffers: [],
};

const promotionalOfferSlice = createSlice({
  name: 'promotionalOffers',
  initialState,
  reducers: {
    addPromotionalOffer: (state, action) => {
      state.promotionalOffers.push(action.payload);
    },
    updatePromotionalOffer: (state, action) => {
      const index = state.promotionalOffers.findIndex(offer => offer.id === action.payload.id);
      if (index !== -1) {
        state.promotionalOffers[index] = action.payload;
      }
    },
  },
});

export const { addPromotionalOffer, updatePromotionalOffer } = promotionalOfferSlice.actions;
export default promotionalOfferSlice.reducer;
