import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box, Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, 
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, 
  Typography, IconButton, Checkbox, FormControlLabel, FormGroup, Popover, Collapse
} from '@mui/material';
import { Add as AddIcon, Search as SearchIcon, ExpandMore, ExpandLess, FilterList as FilterListIcon } from '@mui/icons-material';
import { addItem, updateItemLocationPrice, updateVarianceLocationPrice } from '../features/itemSlice';

const initialItemState = {
  id: '',
  name: '',
  category: '',
  subcategory: '',
  itemGroup: '',
  uom: '',
  tax: '',
  price: '',
  description: '',
  status: '',
  stockQuantity: '',
  reorderLevel: '',
  itemType: '',
  hsnCode: '',
  createdDate: '',
  lastUpdatedDate: '',
  qrcode: '',
  sku: '',
  shelfLife: '',
  image: '',
  variances: [],
};

const initialVarianceState = {
  varianceName: '',
  uom: '',
  price: '',
  qrcode: '',
};

const locations = ['Location1', 'Location2', 'Location3']; // Example locations

const allHeaders = [
  'id', 'name', 'category', 'subcategory', 'itemGroup', 'uom', 'tax', 'price',
  'description', 'status', 'stockQuantity', 'reorderLevel', 'itemType', 'hsnCode',
  'createdDate', 'lastUpdatedDate', 'qrcode', 'sku', 'shelfLife', 'image'
];

function Items() {
  const dispatch = useDispatch();
  const items = useSelector((state) => state.items.items);
  const [open, setOpen] = useState(false);
  const [openVariance, setOpenVariance] = useState(false);
  const [openPriceOverride, setOpenPriceOverride] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [overrideContext, setOverrideContext] = useState(null); // For storing context of price override (item or variance)
  const [newItem, setNewItem] = useState(initialItemState);
  const [newVariance, setNewVariance] = useState(initialVarianceState);
  const [search, setSearch] = useState('');
  const [variances, setVariances] = useState([]);
  const [expanded, setExpanded] = useState({});
  const [overrideLocationPrices, setOverrideLocationPrices] = useState({});
  const [selectedHeaders, setSelectedHeaders] = useState(allHeaders);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setNewItem(initialItemState);
    setVariances([]);
  };

  const handleVarianceOpen = () => setOpenVariance(true);
  const handleVarianceClose = () => setOpenVariance(false);

  const handlePopoverOpen = (event) => setAnchorEl(event.currentTarget);
  const handlePopoverClose = () => setAnchorEl(null);

  const handleOverrideOpen = (context) => {
    setOverrideContext(context);
    setOverrideLocationPrices(context.locationPrices || {});
    setOpenPriceOverride(true);
  };
  const handleOverrideClose = () => setOpenPriceOverride(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewItem((prev) => ({ ...prev, [name]: value }));
  };

  const handleVarianceChange = (e) => {
    const { name, value } = e.target;
    setNewVariance((prev) => ({ ...prev, [name]: value }));
  };

  const handleOverrideChange = (e) => {
    const { name, value } = e.target;
    setOverrideLocationPrices((prev) => ({ ...prev, [name]: value }));
  };

  const handleVarianceSubmit = () => {
    setVariances((prev) => [...prev, newVariance]);
    setNewVariance(initialVarianceState);
    handleVarianceClose();
  };

  const handleSubmit = () => {
    const itemWithVariances = { ...newItem, variances };
    dispatch(addItem(itemWithVariances));
    handleClose();
  };

  const handleOverrideSubmit = () => {
    const updatedContext = { ...overrideContext, locationPrices: overrideLocationPrices };
    if (overrideContext.type === 'item') {
      dispatch(updateItemLocationPrice({ itemId: updatedContext.id, locationPrices: overrideLocationPrices }));
    } else if (overrideContext.type === 'variance') {
      dispatch(updateVarianceLocationPrice({ itemId: updatedContext.itemId, varianceId: updatedContext.id, locationPrices: overrideLocationPrices }));
    }
    handleOverrideClose();
  };

  const handleHeaderSelectChange = (header) => {
    setSelectedHeaders((prev) =>
      prev.includes(header) ? prev.filter((h) => h !== header) : [...prev, header]
    );
  };

  const filteredItems = items.filter((item) =>
    item.name.toLowerCase().includes(search.toLowerCase())
  );

  const handleExpandClick = (id) => {
    setExpanded((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const openHeaderSelect = Boolean(anchorEl);

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <TextField
          variant="outlined"
          label="Search Items"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          InputProps={{
            startAdornment: <SearchIcon />,
          }}
        />
        <Button variant="contained" color="primary" onClick={handleOpen} startIcon={<AddIcon />}>
          Add Item
        </Button>
        <IconButton onClick={handlePopoverOpen}>
          <FilterListIcon />
        </IconButton>
        <Popover
          open={openHeaderSelect}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Box p={2}>
            <FormGroup>
              {allHeaders.map((header) => (
                <FormControlLabel
                  key={header}
                  control={
                    <Checkbox
                      checked={selectedHeaders.includes(header)}
                      onChange={() => handleHeaderSelectChange(header)}
                    />
                  }
                  label={header.replace(/([A-Z])/g, ' $1').trim()}
                />
              ))}
            </FormGroup>
            <Button onClick={handlePopoverClose}>Close</Button>
          </Box>
        </Popover>
      </Box>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add Item</DialogTitle>
        <DialogContent>
          {Object.keys(initialItemState).map((key) => key !== 'variances' && (
            <TextField
              key={key}
              margin="dense"
              label={key.replace(/([A-Z])/g, ' $1').trim()}
              name={key}
              value={newItem[key]}
              onChange={handleChange}
              fullWidth
              InputProps={{
                endAdornment: key === 'price' && (
                  <Button onClick={() => handleOverrideOpen({ ...newItem, type: 'item' })}>
                    Price Override
                  </Button>
                ),
              }}
            />
          ))}
          <Box sx={{ mt: 2 }}>
            <Typography variant="h6">Variances</Typography>
            <Button variant="outlined" color="primary" onClick={handleVarianceOpen} startIcon={<AddIcon />}>
              Add Variance
            </Button>
            <Box sx={{ mt: 2 }}>
              {variances.map((variance, index) => (
                <Box key={index} sx={{ mb: 1, p: 1, border: '1px solid grey', borderRadius: 1 }}>
                  {Object.keys(variance).map((key) => (
                    <TextField
                      key={key}
                      margin="dense"
                      label={key.replace(/([A-Z])/g, ' $1').trim()}
                      name={key}
                      value={variance[key]}
                      InputProps={{
                        readOnly: true,
                        endAdornment: key === 'price' && (
                          <Button onClick={() => handleOverrideOpen({ ...variance, itemId: newItem.id, type: 'variance' })}>
                            Price Override
                          </Button>
                        ),
                      }}
                      fullWidth
                    />
                  ))}
                </Box>
              ))}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit} color="primary">Submit</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openVariance} onClose={handleVarianceClose}>
        <DialogTitle>Add Variance</DialogTitle>
        <DialogContent>
          {Object.keys(initialVarianceState).map((key) => (
            <TextField
              key={key}
              margin="dense"
              label={key.replace(/([A-Z])/g, ' $1').trim()}
              name={key}
              value={newVariance[key]}
              onChange={handleVarianceChange}
              fullWidth
              InputProps={{
                endAdornment: key === 'price' && (
                  <Button onClick={() => handleOverrideOpen({ ...newVariance, type: 'variance' })}>
                    Price Override
                  </Button>
                ),
              }}
            />
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleVarianceClose}>Cancel</Button>
          <Button onClick={handleVarianceSubmit} color="primary">Submit</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openPriceOverride} onClose={handleOverrideClose}>
        <DialogTitle>Price Override</DialogTitle>
        <DialogContent>
          {locations.map((location) => (
            <Box key={location} sx={{ display: 'flex', mb: 2 }}>
              <TextField
                label="Location"
                value={location}
                InputProps={{
                  readOnly: true,
                }}
                sx={{ mr: 2 }}
              />
              <TextField
                label="Price"
                name={location}
                value={overrideLocationPrices[location] || ''}
                onChange={handleOverrideChange}
                fullWidth
              />
            </Box>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOverrideClose}>Cancel</Button>
          <Button onClick={handleOverrideSubmit} color="primary">Submit</Button>
        </DialogActions>
      </Dialog>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {allHeaders.map((header) => (
                selectedHeaders.includes(header) && (
                  <TableCell key={header}>{header.replace(/([A-Z])/g, ' $1').trim()}</TableCell>
                )
              ))}
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredItems.map((item, index) => (
              <React.Fragment key={index}>
                <TableRow>
                  {selectedHeaders.map((header, i) => (
                    <TableCell key={i}>
                      {item[header]}
                    </TableCell>
                  ))}
                  <TableCell>
                    <IconButton onClick={() => handleExpandClick(item.id)}>
                      {expanded[item.id] ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={selectedHeaders.length + 1}>
                    <Collapse in={expanded[item.id]} timeout="auto" unmountOnExit>
                      <Box margin={1}>
                        <Typography variant="h6" gutterBottom component="div">
                          Variances
                        </Typography>
                        {item.variances.length > 0 ? (
                          item.variances.map((variance, vIndex) => (
                            <Box key={vIndex} sx={{ mb: 1, p: 1, border: '1px solid grey', borderRadius: 1 }}>
                              {Object.keys(initialVarianceState).map((key) => (
                                <TextField
                                  key={key}
                                  margin="dense"
                                  label={key.replace(/([A-Z])/g, ' $1').trim()}
                                  name={key}
                                  value={variance[key]}
                                  InputProps={{
                                    readOnly: true,
                                    endAdornment: key === 'price' && (
                                      <Button onClick={() => handleOverrideOpen({ ...variance, itemId: item.id, type: 'variance' })}>
                                        Price Override
                                      </Button>
                                    ),
                                  }}
                                  fullWidth
                                />
                              ))}
                            </Box>
                          ))
                        ) : (
                          <Typography>No variances available.</Typography>
                        )}
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default Items;
