import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  items: [],
};

const itemSlice = createSlice({
  name: 'items',
  initialState,
  reducers: {
    addItem: (state, action) => {
      state.items.push(action.payload);
    },
    updateItemLocationPrice: (state, action) => {
      const { itemId, locationPrices } = action.payload;
      const item = state.items.find(item => item.id === itemId);
      if (item) {
        item.locationPrices = locationPrices;
      }
    },
    updateVarianceLocationPrice: (state, action) => {
      const { itemId, varianceId, locationPrices } = action.payload;
      const item = state.items.find(item => item.id === itemId);
      if (item) {
        const variance = item.variances.find(variance => variance.id === varianceId);
        if (variance) {
          variance.locationPrices = locationPrices;
        }
      }
    },
  },
});

export const { addItem, updateItemLocationPrice, updateVarianceLocationPrice } = itemSlice.actions;

export default itemSlice.reducer;
