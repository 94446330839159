import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box, Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  Typography, IconButton, Switch
} from '@mui/material';
import { Add as AddIcon, Search as SearchIcon } from '@mui/icons-material';
import { addDiscount, updateDiscount, toggleDiscount } from '../features/discountSlice';

const initialDiscountState = {
  discountName: '',
  discountPercentage: '',
  isActive: false
};

function Discount() {
  const dispatch = useDispatch();
  const discounts = useSelector((state) => state.discounts.discounts);
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [newDiscount, setNewDiscount] = useState(initialDiscountState);
  const [currentDiscount, setCurrentDiscount] = useState(null);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setNewDiscount(initialDiscountState);
  };

  const handleEditOpen = (discount) => {
    setCurrentDiscount(discount);
    setNewDiscount(discount);
    setEditOpen(true);
  };
  const handleEditClose = () => {
    setEditOpen(false);
    setNewDiscount(initialDiscountState);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewDiscount((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = () => {
    dispatch(addDiscount(newDiscount));
    handleClose();
  };

  const handleUpdate = () => {
    dispatch(updateDiscount(newDiscount));
    handleEditClose();
  };

  const handleToggle = (id) => {
    dispatch(toggleDiscount(id));
  };

  const filteredDiscounts = discounts.filter((discount) =>
    discount.discountName.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <TextField
          variant="outlined"
          label="Search Discount"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          InputProps={{
            startAdornment: <SearchIcon />,
          }}
        />
        <Button variant="contained" color="primary" onClick={handleOpen} startIcon={<AddIcon />}>
          Add Discount
        </Button>
      </Box>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add Discount</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Discount Name"
            name="discountName"
            value={newDiscount.discountName}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Discount Percentage"
            name="discountPercentage"
            value={newDiscount.discountPercentage}
            onChange={handleChange}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit} color="primary">Submit</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={editOpen} onClose={handleEditClose}>
        <DialogTitle>Edit Discount</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Discount Name"
            name="discountName"
            value={newDiscount.discountName}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Discount Percentage"
            name="discountPercentage"
            value={newDiscount.discountPercentage}
            onChange={handleChange}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditClose}>Cancel</Button>
          <Button onClick={handleUpdate} color="primary">Update</Button>
        </DialogActions>
      </Dialog>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Discount Name</TableCell>
              <TableCell>Discount Percentage</TableCell>
              <TableCell>Active</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredDiscounts.map((discount, index) => (
              <TableRow key={index} onClick={() => handleEditOpen(discount)} style={{ cursor: 'pointer' }}>
                <TableCell>{discount.discountName}</TableCell>
                <TableCell>{discount.discountPercentage}</TableCell>
                <TableCell>
                  <Switch
                    checked={discount.isActive}
                    onChange={() => handleToggle(discount.id)}
                    color="primary"
                    onClick={(e) => e.stopPropagation()}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default Discount;
