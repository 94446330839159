import React from 'react';
import { Container, Typography } from '@mui/material';

function Dashboard() {
  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Dashboard
      </Typography>
      <Typography variant="body1">
        Welcome to the Admin Panel. Use the navigation bar to manage items and customers.
      </Typography>
    </Container>
  );
}

export default Dashboard;
