import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { CssBaseline, Box, IconButton, Toolbar, Typography } from '@mui/material';
import { Menu as MenuIcon, Notifications, AccountCircle } from '@mui/icons-material';
import SideMenu from './components/SideMenu';
import SubMenu from './components/SubMenu';
import Dashboard from './pages/Dashboard';
import Items from './pages/Items';
import Customers from './pages/Customers';
import Locations from './pages/Locations';
import Subcategory from './pages/Subcategory';
import Category from './pages/Category';
import Uom from './pages/Uom';
import Tax from './pages/Tax';
import Discount from './pages/Discount';
import PromotionalOffers from './pages/PromotionalOffers';
import { Provider } from 'react-redux';
import store from './store';

const drawerWidth = 240;

function App() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState(null);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleMenuClick = (menuItem) => {
    setSelectedMenu(menuItem);
    setDrawerOpen(false);
  };

  return (
    <Provider store={store}>
      <Router>
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />
          <SideMenu open={drawerOpen} onClose={handleDrawerClose} onMenuClick={handleMenuClick} />
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              p: 3,
              width: `calc(100% - ${drawerOpen ? drawerWidth : 0}px)`,
              transition: 'width 0.3s',
              marginLeft: drawerOpen ? '240px' : '0',
            }}
          >
            <Toolbar
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{ mr: 2, ...(drawerOpen && { display: 'none' }) }}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" noWrap>
                Your App Name
              </Typography>
              <div>
                <IconButton color="inherit">
                  <Notifications />
                </IconButton>
                <IconButton color="inherit">
                  <AccountCircle />
                </IconButton>
                <Typography variant="body1" sx={{ display: 'inline', marginLeft: 1 }}>
                  Username
                </Typography>
              </div>
            </Toolbar>
            {selectedMenu && <SubMenu subItems={selectedMenu.subItems} />}
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/items" element={<Items />} />
              <Route path="/customers" element={<Customers />} />
              <Route path="/locations" element={<Locations />} />
              <Route path="/subcategory" element={<Subcategory />} />
              <Route path="/category" element={<Category />} />
              <Route path="/uom" element={<Uom />} />
              <Route path="/tax" element={<Tax />} />
              <Route path="/discount" element={<Discount />} />
              <Route path="/promotionaloffers" element={<PromotionalOffers />} />
              {/* Add more routes for other pages as needed */}
            </Routes>
          </Box>
        </Box>
      </Router>
    </Provider>
  );
}

export default App;
