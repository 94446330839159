import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box, Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, 
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, 
  Typography, IconButton
} from '@mui/material';
import { Add as AddIcon, Search as SearchIcon } from '@mui/icons-material';
import { addTax, updateTax } from '../features/taxSlice';

const initialTaxState = {
  taxName: '',
  taxPercentage: ''
};

function Tax() {
  const dispatch = useDispatch();
  const taxes = useSelector((state) => state.taxes.taxes);
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [newTax, setNewTax] = useState(initialTaxState);
  const [currentTax, setCurrentTax] = useState(null);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setNewTax(initialTaxState);
  };

  const handleEditOpen = (tax) => {
    setCurrentTax(tax);
    setNewTax(tax);
    setEditOpen(true);
  };
  const handleEditClose = () => {
    setEditOpen(false);
    setNewTax(initialTaxState);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewTax((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = () => {
    dispatch(addTax(newTax));
    handleClose();
  };

  const handleUpdate = () => {
    dispatch(updateTax(newTax));
    handleEditClose();
  };

  const filteredTaxes = taxes.filter((tax) =>
    tax.taxName.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <TextField
          variant="outlined"
          label="Search Tax"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          InputProps={{
            startAdornment: <SearchIcon />,
          }}
        />
        <Button variant="contained" color="primary" onClick={handleOpen} startIcon={<AddIcon />}>
          Add Tax
        </Button>
      </Box>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add Tax</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Tax Name"
            name="taxName"
            value={newTax.taxName}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Tax Percentage"
            name="taxPercentage"
            value={newTax.taxPercentage}
            onChange={handleChange}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit} color="primary">Submit</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={editOpen} onClose={handleEditClose}>
        <DialogTitle>Edit Tax</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Tax Name"
            name="taxName"
            value={newTax.taxName}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Tax Percentage"
            name="taxPercentage"
            value={newTax.taxPercentage}
            onChange={handleChange}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditClose}>Cancel</Button>
          <Button onClick={handleUpdate} color="primary">Update</Button>
        </DialogActions>
      </Dialog>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Tax Name</TableCell>
              <TableCell>Tax Percentage</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredTaxes.map((tax, index) => (
              <TableRow key={index} onClick={() => handleEditOpen(tax)} style={{ cursor: 'pointer' }}>
                <TableCell>{tax.taxName}</TableCell>
                <TableCell>{tax.taxPercentage}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default Tax;
