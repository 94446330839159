import React from 'react';
import CustomerForm from '../components/CustomerForm';

function Customers() {
  return (
    <div>
      <CustomerForm />
    </div>
  );
}

export default Customers;
