import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  subcategories: [],
};

const subcategorySlice = createSlice({
  name: 'subcategories',
  initialState,
  reducers: {
    addSubcategory: (state, action) => {
      state.subcategories.push({ ...action.payload, id: state.subcategories.length + 1 });
    },
    setSubcategories: (state, action) => {
      state.subcategories = action.payload;
    },
  },
});

export const { addSubcategory, setSubcategories } = subcategorySlice.actions;

export default subcategorySlice.reducer;
