import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  taxes: [],
};

const taxSlice = createSlice({
  name: 'taxes',
  initialState,
  reducers: {
    addTax: (state, action) => {
      state.taxes.push({ ...action.payload, id: state.taxes.length + 1 });
    },
    updateTax: (state, action) => {
      const index = state.taxes.findIndex(tax => tax.id === action.payload.id);
      if (index !== -1) {
        state.taxes[index] = action.payload;
      }
    },
    setTaxes: (state, action) => {
      state.taxes = action.payload;
    },
  },
});

export const { addTax, updateTax, setTaxes } = taxSlice.actions;

export default taxSlice.reducer;
