import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  categories: [],
};

const categorySlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    addCategory: (state, action) => {
      state.categories.push({ ...action.payload, id: state.categories.length + 1 });
    },
    setCategories: (state, action) => {
      state.categories = action.payload;
    },
  },
});

export const { addCategory, setCategories } = categorySlice.actions;

export default categorySlice.reducer;
