import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  locations: [],
};

const locationSlice = createSlice({
  name: 'locations',
  initialState,
  reducers: {
    addLocation: (state, action) => {
      state.locations.push(action.payload);
    },
    setLocations: (state, action) => {
      state.locations = action.payload;
    },
  },
});

export const { addLocation, setLocations } = locationSlice.actions;

export default locationSlice.reducer;
