import React from 'react';
import { Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function SubMenu({ subItems }) {
  const navigate = useNavigate();

  return (
    <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
      {subItems.map((subItem, index) => (
        <Button key={index} variant="contained" onClick={() => navigate(`/${subItem.toLowerCase().replace(/\s+/g, '')}`)}>
          {subItem}
        </Button>
      ))}
    </Box>
  );
}

export default SubMenu;
