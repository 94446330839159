import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  discounts: [],
};

const discountSlice = createSlice({
  name: 'discounts',
  initialState,
  reducers: {
    addDiscount: (state, action) => {
      state.discounts.push({ ...action.payload, id: state.discounts.length + 1 });
    },
    updateDiscount: (state, action) => {
      const index = state.discounts.findIndex(discount => discount.id === action.payload.id);
      if (index !== -1) {
        state.discounts[index] = action.payload;
      }
    },
    toggleDiscount: (state, action) => {
      state.discounts = state.discounts.map(discount => 
        discount.id === action.payload ? { ...discount, isActive: true } : { ...discount, isActive: false }
      );
    },
    setDiscounts: (state, action) => {
      state.discounts = action.payload;
    },
  },
});

export const { addDiscount, updateDiscount, toggleDiscount, setDiscounts } = discountSlice.actions;

export default discountSlice.reducer;
