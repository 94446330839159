import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  uoms: [],
};

const uomSlice = createSlice({
  name: 'uoms',
  initialState,
  reducers: {
    addUom: (state, action) => {
      state.uoms.push({ ...action.payload, id: state.uoms.length + 1 });
    },
    updateUom: (state, action) => {
      const index = state.uoms.findIndex(uom => uom.id === action.payload.id);
      if (index !== -1) {
        state.uoms[index] = action.payload;
      }
    },
    setUoms: (state, action) => {
      state.uoms = action.payload;
    },
  },
});

export const { addUom, updateUom, setUoms } = uomSlice.actions;

export default uomSlice.reducer;
