import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box, Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, 
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, 
  Typography, IconButton
} from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import { addSubcategory } from '../features/subcategorySlice';

const initialSubcategoryState = {
  name: '',
  items: []
};

function Subcategory() {
  const dispatch = useDispatch();
  const subcategories = useSelector((state) => state.subcategories.subcategories);
  const [open, setOpen] = useState(false);
  const [newSubcategory, setNewSubcategory] = useState(initialSubcategoryState);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setNewSubcategory(initialSubcategoryState);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewSubcategory((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = () => {
    dispatch(addSubcategory(newSubcategory));
    handleClose();
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Typography variant="h4">Subcategories</Typography>
        <Button variant="contained" color="primary" onClick={handleOpen} startIcon={<AddIcon />}>
          Create Subcategory
        </Button>
      </Box>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Create Subcategory</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Subcategory Name"
            name="name"
            value={newSubcategory.name}
            onChange={handleChange}
            fullWidth
          />
          <Button variant="contained" color="primary" sx={{ mt: 2 }}>
            Assign Items
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit} color="primary">Submit</Button>
        </DialogActions>
      </Dialog>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {subcategories.map((subcategory, index) => (
              <TableRow key={index}>
                <TableCell>{subcategory.name}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default Subcategory;
