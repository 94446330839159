import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box, Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  Typography, IconButton, Checkbox, FormControlLabel, FormGroup, Select, MenuItem, InputLabel, FormControl, ListItemText, FormLabel, RadioGroup, Radio
} from '@mui/material';
import { Add as AddIcon, Search as SearchIcon } from '@mui/icons-material';
import { addPromotionalOffer, updatePromotionalOffer } from '../features/promotionalOfferSlice';
import { DateRangePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import UploadButton from '../components/UploadButton'; // Ensure this component exists

const initialPromotionalOfferState = {
  appTypes: [],
  offerName: '',
  locations: [],
  dateRange: [null, null],
  timeRange: '',
  weekdays: [],
  items: [],
  variances: [],
  categories: [],
  subcategories: [],
  configuration: '',
  customers: [],
  image: ''
};

const appTypesOptions = ['Self Order', 'POS', 'Ecommerce', 'Dinein'];
const locationsOptions = ['Location1', 'Location2', 'Location3'];
const weekdaysOptions = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
const itemsOptions = ['Item1', 'Item2', 'Item3']; // Replace with actual items
const variancesOptions = ['Variance1', 'Variance2', 'Variance3']; // Replace with actual variances
const categoriesOptions = ['Category1', 'Category2', 'Category3']; // Replace with actual categories
const subcategoriesOptions = ['Subcategory1', 'Subcategory2', 'Subcategory3']; // Replace with actual subcategories
const customersOptions = ['Customer1', 'Customer2', 'Customer3']; // Replace with actual customers

function PromotionalOffers() {
  const dispatch = useDispatch();
  const promotionalOffers = useSelector((state) => state.promotionalOffers.promotionalOffers);
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [newPromotionalOffer, setNewPromotionalOffer] = useState(initialPromotionalOfferState);
  const [currentPromotionalOffer, setCurrentPromotionalOffer] = useState(null);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setNewPromotionalOffer(initialPromotionalOfferState);
  };

  const handleEditOpen = (offer) => {
    setCurrentPromotionalOffer(offer);
    setNewPromotionalOffer(offer);
    setEditOpen(true);
  };
  const handleEditClose = () => {
    setEditOpen(false);
    setNewPromotionalOffer(initialPromotionalOfferState);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewPromotionalOffer((prev) => ({ ...prev, [name]: value }));
  };

  const handleMultiSelectChange = (name, value) => {
    setNewPromotionalOffer((prev) => ({ ...prev, [name]: value }));
  };

  const handleImageUpload = (image) => {
    setNewPromotionalOffer((prev) => ({ ...prev, image }));
  };

  const handleSubmit = () => {
    dispatch(addPromotionalOffer(newPromotionalOffer));
    handleClose();
  };

  const handleUpdate = () => {
    dispatch(updatePromotionalOffer(newPromotionalOffer));
    handleEditClose();
  };

  const filteredPromotionalOffers = promotionalOffers.filter((offer) =>
    offer.offerName.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <TextField
          variant="outlined"
          label="Search Promotional Offers"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          InputProps={{
            startAdornment: <SearchIcon />,
          }}
        />
        <Button variant="contained" color="primary" onClick={handleOpen} startIcon={<AddIcon />}>
          Add Promotional Offer
        </Button>
      </Box>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add Promotional Offer</DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin="normal">
            <InputLabel>App Types</InputLabel>
            <Select
              multiple
              value={newPromotionalOffer.appTypes}
              onChange={(e) => handleMultiSelectChange('appTypes', e.target.value)}
              renderValue={(selected) => selected.join(', ')}
            >
              {appTypesOptions.map((appType) => (
                <MenuItem key={appType} value={appType}>
                  <Checkbox checked={newPromotionalOffer.appTypes.indexOf(appType) > -1} />
                  <ListItemText primary={appType} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            margin="dense"
            label="Promotional Offer Name"
            name="offerName"
            value={newPromotionalOffer.offerName}
            onChange={handleChange}
            fullWidth
          />

          <FormControl fullWidth margin="normal">
            <InputLabel>Locations</InputLabel>
            <Select
              multiple
              value={newPromotionalOffer.locations}
              onChange={(e) => handleMultiSelectChange('locations', e.target.value)}
              renderValue={(selected) => selected.join(', ')}
            >
              {locationsOptions.map((location) => (
                <MenuItem key={location} value={location}>
                  <Checkbox checked={newPromotionalOffer.locations.indexOf(location) > -1} />
                  <ListItemText primary={location} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateRangePicker
              startText="Start Date"
              endText="End Date"
              value={newPromotionalOffer.dateRange}
              onChange={(newValue) => handleMultiSelectChange('dateRange', newValue)}
              renderInput={(startProps, endProps) => (
                <React.Fragment>
                  <TextField {...startProps} fullWidth margin="normal" />
                  <Box sx={{ mx: 2 }}> to </Box>
                  <TextField {...endProps} fullWidth margin="normal" />
                </React.Fragment>
              )}
            />
          </LocalizationProvider>

          <TextField
            margin="dense"
            label="Time Range"
            name="timeRange"
            value={newPromotionalOffer.timeRange}
            onChange={handleChange}
            fullWidth
          />

          <FormControl fullWidth margin="normal">
            <InputLabel>Weekdays</InputLabel>
            <Select
              multiple
              value={newPromotionalOffer.weekdays}
              onChange={(e) => handleMultiSelectChange('weekdays', e.target.value)}
              renderValue={(selected) => selected.join(', ')}
            >
              {weekdaysOptions.map((day) => (
                <MenuItem key={day} value={day}>
                  <Checkbox checked={newPromotionalOffer.weekdays.indexOf(day) > -1} />
                  <ListItemText primary={day} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth margin="normal">
            <InputLabel>Items</InputLabel>
            <Select
              multiple
              value={newPromotionalOffer.items}
              onChange={(e) => handleMultiSelectChange('items', e.target.value)}
              renderValue={(selected) => selected.join(', ')}
            >
              {itemsOptions.map((item) => (
                <MenuItem key={item} value={item}>
                  <Checkbox checked={newPromotionalOffer.items.indexOf(item) > -1} />
                  <ListItemText primary={item} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth margin="normal">
            <InputLabel>Variances</InputLabel>
            <Select
              multiple
              value={newPromotionalOffer.variances}
              onChange={(e) => handleMultiSelectChange('variances', e.target.value)}
              renderValue={(selected) => selected.join(', ')}
            >
              {variancesOptions.map((variance) => (
                <MenuItem key={variance} value={variance}>
                  <Checkbox checked={newPromotionalOffer.variances.indexOf(variance) > -1} />
                  <ListItemText primary={variance} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth margin="normal">
            <InputLabel>Categories</InputLabel>
            <Select
              multiple
              value={newPromotionalOffer.categories}
              onChange={(e) => handleMultiSelectChange('categories', e.target.value)}
              renderValue={(selected) => selected.join(', ')}
            >
              {categoriesOptions.map((category) => (
                <MenuItem key={category} value={category}>
                  <Checkbox checked={newPromotionalOffer.categories.indexOf(category) > -1} />
                  <ListItemText primary={category} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth margin="normal">
            <InputLabel>Subcategories</InputLabel>
            <Select
              multiple
              value={newPromotionalOffer.subcategories}
              onChange={(e) => handleMultiSelectChange('subcategories', e.target.value)}
              renderValue={(selected) => selected.join(', ')}
            >
              {subcategoriesOptions.map((subcategory) => (
                <MenuItem key={subcategory} value={subcategory}>
                  <Checkbox checked={newPromotionalOffer.subcategories.indexOf(subcategory) > -1} />
                  <ListItemText primary={subcategory} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl component="fieldset" margin="normal">
            <FormLabel component="legend">Configuration</FormLabel>
            <RadioGroup
              name="configuration"
              value={newPromotionalOffer.configuration}
              onChange={handleChange}
            >
              <FormControlLabel value="discount" control={<Radio />} label="Discount %" />
              <FormControlLabel value="orderValueDiscount" control={<Radio />} label="Order Value + Discount %" />
              <FormControlLabel value="buy1Get1" control={<Radio />} label="Buy 1 Get 1" />
              <FormControlLabel value="buy2Get1" control={<Radio />} label="Buy 2 Get 1" />
            </RadioGroup>
          </FormControl>

          <FormControl fullWidth margin="normal">
            <InputLabel>Customers</InputLabel>
            <Select
              multiple
              value={newPromotionalOffer.customers}
              onChange={(e) => handleMultiSelectChange('customers', e.target.value)}
              renderValue={(selected) => selected.join(', ')}
            >
              {customersOptions.map((customer) => (
                <MenuItem key={customer} value={customer}>
                  <Checkbox checked={newPromotionalOffer.customers.indexOf(customer) > -1} />
                  <ListItemText primary={customer} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <UploadButton onUpload={handleImageUpload} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit} color="primary">Submit</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={editOpen} onClose={handleEditClose}>
        <DialogTitle>Edit Promotional Offer</DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin="normal">
            <InputLabel>App Types</InputLabel>
            <Select
              multiple
              value={newPromotionalOffer.appTypes}
              onChange={(e) => handleMultiSelectChange('appTypes', e.target.value)}
              renderValue={(selected) => selected.join(', ')}
            >
              {appTypesOptions.map((appType) => (
                <MenuItem key={appType} value={appType}>
                  <Checkbox checked={newPromotionalOffer.appTypes.indexOf(appType) > -1} />
                  <ListItemText primary={appType} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            margin="dense"
            label="Promotional Offer Name"
            name="offerName"
            value={newPromotionalOffer.offerName}
            onChange={handleChange}
            fullWidth
          />

          <FormControl fullWidth margin="normal">
            <InputLabel>Locations</InputLabel>
            <Select
              multiple
              value={newPromotionalOffer.locations}
              onChange={(e) => handleMultiSelectChange('locations', e.target.value)}
              renderValue={(selected) => selected.join(', ')}
            >
              {locationsOptions.map((location) => (
                <MenuItem key={location} value={location}>
                  <Checkbox checked={newPromotionalOffer.locations.indexOf(location) > -1} />
                  <ListItemText primary={location} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateRangePicker
              startText="Start Date"
              endText="End Date"
              value={newPromotionalOffer.dateRange}
              onChange={(newValue) => handleMultiSelectChange('dateRange', newValue)}
              renderInput={(startProps, endProps) => (
                <React.Fragment>
                  <TextField {...startProps} fullWidth margin="normal" />
                  <Box sx={{ mx: 2 }}> to </Box>
                  <TextField {...endProps} fullWidth margin="normal" />
                </React.Fragment>
              )}
            />
          </LocalizationProvider>

          <TextField
            margin="dense"
            label="Time Range"
            name="timeRange"
            value={newPromotionalOffer.timeRange}
            onChange={handleChange}
            fullWidth
          />

          <FormControl fullWidth margin="normal">
            <InputLabel>Weekdays</InputLabel>
            <Select
              multiple
              value={newPromotionalOffer.weekdays}
              onChange={(e) => handleMultiSelectChange('weekdays', e.target.value)}
              renderValue={(selected) => selected.join(', ')}
            >
              {weekdaysOptions.map((day) => (
                <MenuItem key={day} value={day}>
                  <Checkbox checked={newPromotionalOffer.weekdays.indexOf(day) > -1} />
                  <ListItemText primary={day} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth margin="normal">
            <InputLabel>Items</InputLabel>
            <Select
              multiple
              value={newPromotionalOffer.items}
              onChange={(e) => handleMultiSelectChange('items', e.target.value)}
              renderValue={(selected) => selected.join(', ')}
            >
              {itemsOptions.map((item) => (
                <MenuItem key={item} value={item}>
                  <Checkbox checked={newPromotionalOffer.items.indexOf(item) > -1} />
                  <ListItemText primary={item} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth margin="normal">
            <InputLabel>Variances</InputLabel>
            <Select
              multiple
              value={newPromotionalOffer.variances}
              onChange={(e) => handleMultiSelectChange('variances', e.target.value)}
              renderValue={(selected) => selected.join(', ')}
            >
              {variancesOptions.map((variance) => (
                <MenuItem key={variance} value={variance}>
                  <Checkbox checked={newPromotionalOffer.variances.indexOf(variance) > -1} />
                  <ListItemText primary={variance} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth margin="normal">
            <InputLabel>Categories</InputLabel>
            <Select
              multiple
              value={newPromotionalOffer.categories}
              onChange={(e) => handleMultiSelectChange('categories', e.target.value)}
              renderValue={(selected) => selected.join(', ')}
            >
              {categoriesOptions.map((category) => (
                <MenuItem key={category} value={category}>
                  <Checkbox checked={newPromotionalOffer.categories.indexOf(category) > -1} />
                  <ListItemText primary={category} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth margin="normal">
            <InputLabel>Subcategories</InputLabel>
            <Select
              multiple
              value={newPromotionalOffer.subcategories}
              onChange={(e) => handleMultiSelectChange('subcategories', e.target.value)}
              renderValue={(selected) => selected.join(', ')}
            >
              {subcategoriesOptions.map((subcategory) => (
                <MenuItem key={subcategory} value={subcategory}>
                  <Checkbox checked={newPromotionalOffer.subcategories.indexOf(subcategory) > -1} />
                  <ListItemText primary={subcategory} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl component="fieldset" margin="normal">
            <FormLabel component="legend">Configuration</FormLabel>
            <RadioGroup
              name="configuration"
              value={newPromotionalOffer.configuration}
              onChange={handleChange}
            >
              <FormControlLabel value="discount" control={<Radio />} label="Discount %" />
              <FormControlLabel value="orderValueDiscount" control={<Radio />} label="Order Value + Discount %" />
              <FormControlLabel value="buy1Get1" control={<Radio />} label="Buy 1 Get 1" />
              <FormControlLabel value="buy2Get1" control={<Radio />} label="Buy 2 Get 1" />
            </RadioGroup>
          </FormControl>

          <FormControl fullWidth margin="normal">
            <InputLabel>Customers</InputLabel>
            <Select
              multiple
              value={newPromotionalOffer.customers}
              onChange={(e) => handleMultiSelectChange('customers', e.target.value)}
              renderValue={(selected) => selected.join(', ')}
            >
              {customersOptions.map((customer) => (
                <MenuItem key={customer} value={customer}>
                  <Checkbox checked={newPromotionalOffer.customers.indexOf(customer) > -1} />
                  <ListItemText primary={customer} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <UploadButton onUpload={handleImageUpload} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditClose}>Cancel</Button>
          <Button onClick={handleUpdate} color="primary">Update</Button>
        </DialogActions>
      </Dialog>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Offer Name</TableCell>
              <TableCell>App Types</TableCell>
              <TableCell>Locations</TableCell>
              <TableCell>Date Range</TableCell>
              <TableCell>Time Range</TableCell>
              <TableCell>Weekdays</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredPromotionalOffers.map((offer, index) => (
              <TableRow key={index} onClick={() => handleEditOpen(offer)} style={{ cursor: 'pointer' }}>
                <TableCell>{offer.offerName}</TableCell>
                <TableCell>{offer.appTypes.join(', ')}</TableCell>
                <TableCell>{offer.locations.join(', ')}</TableCell>
                <TableCell>{offer.dateRange.join(' to ')}</TableCell>
                <TableCell>{offer.timeRange}</TableCell>
                <TableCell>{offer.weekdays.join(', ')}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default PromotionalOffers;
