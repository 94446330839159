import { configureStore } from '@reduxjs/toolkit';
import itemReducer from './features/itemSlice';
import locationReducer from './features/locationSlice';
import subcategoryReducer from './features/subcategorySlice';
import categoryReducer from './features/categorySlice';
import uomReducer from './features/uomSlice';
import taxReducer from './features/taxSlice';
import discountReducer from './features/discountSlice';
import promotionalOfferReducer from './features/promotionalOfferSlice';

const store = configureStore({
  reducer: {
    items: itemReducer,
    locations: locationReducer,
    subcategories: subcategoryReducer,
    categories: categoryReducer,
    uoms: uomReducer,
    taxes: taxReducer,
    discounts: discountReducer,
    promotionalOffers: promotionalOfferReducer,
  },
});

export default store;
