import React from 'react';
import { Drawer, List, ListItem, ListItemText, IconButton, Toolbar } from '@mui/material';
import { ChevronLeft } from '@mui/icons-material';

const drawerWidth = 240;

const menuItems = [
  { text: 'Master Admin', subItems: ['Locations', 'Items', 'Category', 'Subcategory', 'Item Groups', 'Uom', 'Tax', 'Vehicle', 'Discount'] },
  { text: 'YEN-POS', subItems: ['Cash Management', 'Bill Receipts', 'EB Reading'] },
  { text: 'YEN-HRM', subItems: [] },
  { text: 'YEN-CRM', subItems: ['Promotional Offers'] },
  { text: 'YEN-BOOK', subItems: [] },
  { text: 'YEN-PURCHASE', subItems: [] },
  { text: 'ACCOUNT SETTINGS', subItems: [] },
];

function SideMenu({ open, onClose, onMenuClick }) {
  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
      }}
      variant="persistent"
      anchor="left"
      open={open}
    >
      <Toolbar>
        <IconButton onClick={onClose}>
          <ChevronLeft />
        </IconButton>
      </Toolbar>
      <List>
        {menuItems.map((menuItem, index) => (
          <div key={index}>
            <ListItem button onClick={() => onMenuClick(menuItem)}>
              <ListItemText primary={menuItem.text} />
            </ListItem>
          </div>
        ))}
      </List>
    </Drawer>
  );
}

export default SideMenu;
