import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box, Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, 
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, 
  Typography, IconButton, Checkbox, FormControlLabel, FormGroup, Popover
} from '@mui/material';
import { Add as AddIcon, Search as SearchIcon, FilterList as FilterListIcon } from '@mui/icons-material';
import { addLocation } from '../features/locationSlice';

const initialLocationState = {
  id: '',
  name: '',
  address: '',
  country: '',
  state: '',
  city: '',
  postalCode: '',
  phoneNumber: '',
  email: '',
  latitude: '',
  longitude: '',
  status: '',
  description: '',
  openingHours: '',
  managerName: '',
  managerContact: '',
  createdDate: '',
  lastUpdatedDate: ''
};

const allHeaders = [
  'id', 'name', 'address', 'country', 'state', 'city', 'postalCode',
  'phoneNumber', 'email', 'latitude', 'longitude', 'status', 'description',
  'openingHours', 'managerName', 'managerContact', 'createdDate', 'lastUpdatedDate'
];

function Locations() {
  const dispatch = useDispatch();
  const locations = useSelector((state) => state.locations.locations);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [newLocation, setNewLocation] = useState(initialLocationState);
  const [search, setSearch] = useState('');
  const [selectedHeaders, setSelectedHeaders] = useState(allHeaders);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setNewLocation(initialLocationState);
  };

  const handlePopoverOpen = (event) => setAnchorEl(event.currentTarget);
  const handlePopoverClose = () => setAnchorEl(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewLocation((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = () => {
    dispatch(addLocation(newLocation));
    handleClose();
  };

  const handleHeaderSelectChange = (header) => {
    setSelectedHeaders((prev) =>
      prev.includes(header) ? prev.filter((h) => h !== header) : [...prev, header]
    );
  };

  const filteredLocations = locations.filter((location) =>
    location.name.toLowerCase().includes(search.toLowerCase())
  );

  const openHeaderSelect = Boolean(anchorEl);

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <TextField
          variant="outlined"
          label="Search Locations"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          InputProps={{
            startAdornment: <SearchIcon />,
          }}
        />
        <Button variant="contained" color="primary" onClick={handleOpen} startIcon={<AddIcon />}>
          Add Location
        </Button>
        <IconButton onClick={handlePopoverOpen}>
          <FilterListIcon />
        </IconButton>
        <Popover
          open={openHeaderSelect}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Box p={2}>
            <FormGroup>
              {allHeaders.map((header) => (
                <FormControlLabel
                  key={header}
                  control={
                    <Checkbox
                      checked={selectedHeaders.includes(header)}
                      onChange={() => handleHeaderSelectChange(header)}
                    />
                  }
                  label={header.replace(/([A-Z])/g, ' $1').trim()}
                />
              ))}
            </FormGroup>
            <Button onClick={handlePopoverClose}>Close</Button>
          </Box>
        </Popover>
      </Box>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add Location</DialogTitle>
        <DialogContent>
          {Object.keys(initialLocationState).map((key) => (
            <TextField
              key={key}
              margin="dense"
              label={key.replace(/([A-Z])/g, ' $1').trim()}
              name={key}
              value={newLocation[key]}
              onChange={handleChange}
              fullWidth
            />
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit} color="primary">Submit</Button>
        </DialogActions>
      </Dialog>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {allHeaders.map((header) => (
                selectedHeaders.includes(header) && (
                  <TableCell key={header}>{header.replace(/([A-Z])/g, ' $1').trim()}</TableCell>
                )
              ))}
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredLocations.map((location, index) => (
              <TableRow key={index}>
                {selectedHeaders.map((header) => (
                  <TableCell key={header}>
                    {location[header]}
                  </TableCell>
                ))}
                <TableCell>
                  {/* Add actions like edit/delete buttons here */}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default Locations;
